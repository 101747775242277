import React from 'react';
import { Nav, Navbar, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import SideBarMenu from './SideBarMenu';
import Login from './Login';
import Insumos from './inv/Insumos';
import Productos from './inv/Productos';
import Entradas from './inv/Entradas';
import Salidas from './inv/Salidas';
import Kardex from './inv/Kardex';
import ReportesInv from './inv/ReportesInv';
import Proveedores from './cxp/Proveedores';
import PagosCxP from './cxp/PagosCxP';
import Destinos from './agri/Destinos';
import Cultivos from './agri/Cultivos';
import CultivosLote from './agri/CultivosLote';
import Variedades from './agri/Variedades';
import Lotes from './agri/Lotes';
import Actividades from './agri/Actividades';
import ActDiariasMaq from './agri/ActDiariasMaq';
import ConsumoDiesel from './agri/ConsumoDiesel';
import Muestreos from './agri/Muestreos';
import Requisiciones from './compras/Requisiciones';
import OrdenesCompra from './compras/OrdenesCompra';
import Compras from './compras/Compras';
import XmlProvs from './compras/XmlProvs';
import ContaRepos from './conta/ContaRepos';
import Dividendos from './conta/Dividendos';
import Cheques from './conta/Cheques';
import Empleados from './nom/Empleados';
import CapturaEmpleado from './nom/CapturaEmpleado';
import ConsultaEmpleado from './nom/ConsultaEmpleado';
import ActividadesCultivo from './nom/ActividadesCultivo';
import ActividadesNom from './nom/ActividadesNom';
import CapturaDiariaAg from './nom/CapturaDiariaAg';
import Usuarios from './config/Usuarios';
import Notifica from './Notifica';
import Menu from './Menu';

class App extends React.Component {
  constructor(props) {
    super(props);
    let urlws = '';// 'https://www.prod.isaerpweb.com';
    //urlws = 'http://localhost:7115';
    this.state = {
      u: { id: 0, nombre: '', admin: false },
      lg: false,
      opc: '',
      txt:'',
      temp:'1920',
      nombreEmpresa:'El Arco CSL',
      urlws : urlws  
    }
  }
  opcionSeleccionada = (opc,txt) => {
    this.setState({ opc,txt });
  }
  iniciar = (u) => {
    const usu = { id: u.Id, nombre: u.Nombre, admin: u.Admin };
    this.setState({ lg: true, u: usu });
  }
  render() {
    let urlws = this.state.urlws;
    let {opc} = this.state;
    if(opc === "rptBalanza" || opc === "rptBalance" || opc === "rptAnalitica" || opc === "rptAuxiliar" || opc === "rptResultados" || opc === "rptVerificador"  || opc === "rptPolizas")
      opc = "rptConta"
    if(opc === "rptLstEnt" || opc === "rptLstSal" || opc === "rptSdosAlm" || opc === "rptExisAct" || opc === "rptKardex"  || opc === "rptExis")
      opc = "rptInv"
    const estilo = { visibility: this.state.lg ? 'visible' : 'hidden' };
    if(this.state.lg)
      document.body.classList.add('body');
    return (
      <div>
        {this.state.lg ? null : <Login iniciar={this.iniciar} nombre={this.state.nombreEmpresa} urlws={urlws} />}
        <div style={estilo}>
          <div className="row">
            <div className="col-2">
              <img src="/imagenes/logo.png" height="80" />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-10">
                  <Menu opcionSeleccionada={this.opcionSeleccionada} usuario={this.state.u} />
                </div>
                <div className="col">
                  <span style={{ textAlign:'left', color: '#fff', fontWeight:'bolder' }}>usuario:{this.state.u.nombre}</span>
                </div>
              </div>
                <div className="row">
                  <div className="col-7">
                    <span className="opcionActual">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.txt}</span>
                  </div>
                </div>
            </div>
          </div>
          {this.state.lg ?
          <div id="content" style={{ width: '90%' }}>
            {opc === "agroquimicos" ? <Insumos urlws={urlws}  usuario={this.state.u} /> : null}
            {opc === "productos" ? <Productos urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "requisicion" ? <Requisiciones urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "ordencompra" ? <OrdenesCompra urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "entAgro" ? <Entradas depto={1} urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "salAgro" ? <Salidas depto={1} urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "entRef" ? <Entradas depto={2} urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "salRef" ? <Salidas depto={2} urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "kardex" ? <Kardex urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "compras" ? <Compras urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "xmls" ? <XmlProvs urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "rptInv" ? <ReportesInv rpt={this.state.opc} urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "proveedores" ? <Proveedores urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "destinos" ? <Destinos urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "cultivos" ? <Cultivos urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "variedades" ? <Variedades urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "lotes" ? <Lotes urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "loteCult" ? <CultivosLote urlws={urlws} usuario={this.state.u} temp={this.state.temp}/> : null}
            {opc === "actividades" ? <Actividades urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "actividadesNom" ? <ActividadesNom urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "capnom" ? <CapturaEmpleado urlws={urlws} usuario={this.state.u} temp={this.state.temp}/> : null}
            {opc === "connom" ? <ConsultaEmpleado urlws={urlws} usuario={this.state.u} temp={this.state.temp}/> : null}
            {opc === "muestreos" ? <Muestreos urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "diarioMaq" ? <ActDiariasMaq urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "conDiesel" ? <ConsumoDiesel urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "rptConta" ? <ContaRepos rpt={this.state.opc} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "dividendos" ? <Dividendos rpt={this.state.opc} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "cheques" ? <Cheques rpt={this.state.opc} titulo={this.state.txt} urlws={urlws} usuario={this.state.u} /> : null}
            {opc === "cxpPagos" ? <PagosCxP urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "empleados" ? <Empleados urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "actsCultivos" ? <ActividadesCultivo urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "capNomAg" ? <CapturaDiariaAg urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
            {opc === "confUsuarios" ? <Usuarios urlws={urlws} usuario={this.state.u} temp={this.state.temp} /> : null}
          </div>
          :null
          }
        </div>
      </div>
    )
  }
}
export default App;
import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';
import RequisicionesCotizar from './RequisicionesCotizar';

export default class Requisiciones extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            tipo:'E',
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave:'??',
            tipos:[],
            almacenes:[],
            estadosReq:[],
            tipos:[],
            entradas:[],
            usuarios:[],
            temporadas:[],
            lotes:[],
            cotizaciones:[],
            ordenes:[],
            entrada:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/invMovs/tiposReq`;
        resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/invMovs/requisitores`;
        resp = await fetch(url);
        const usuarios = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        url = `${this.props.urlws}/invMovs/statusReq`;
        resp = await fetch(url);
        const estadosReq = await resp.json();
        this.setState({cargando:false, almacenes, usuarios, temporadas, lotes,estadosReq, tipos });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const temp = this.state.Temporada;
        const alm = this.state.almacen;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/wsrequisiciones?alm=${alm}&cve=${cve}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const entradas = await resp.json();
        this.setState({cargando:false, entradas });
    }
    enviarOrdenEmail = async(alm,cve,num,email) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invmovs/enviarOrdenCompra?alm=${alm}&cve=${cve}&num=${num}&email=${email}`;
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok")
            Swal.fire('Atencion','Se envio la orden','success');
        else
            Swal.fire('Atencion',res,'error');
    }

    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Almacen,mov.Clave,mov.Numero);
    }
    cargarMov = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/cargarReq?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        let cotizaciones = [];
        let ordenes = [];
        const entrada = await resp.json();
        if(entrada.Numero === 0)
           entrada.Temporada = this.state.Temporada;
        if(entrada.Status > 1){
            url = `${this.props.urlws}/invMovs/cargarCotizaReq?alm=${alm}&cve=${cve}&num=${num}`;
            resp = await fetch(url);
            cotizaciones = await resp.json();    
            url = `${this.props.urlws}/invMovs/ordenesReq?alm=${alm}&cve=${cve}&num=${num}`;
            resp = await fetch(url);
            ordenes = await resp.json();    
        }
        this.setState({cargando:false, editando:true, entrada, cotizaciones, ordenes });
    }
    cargarCotizas = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/cargarCotizaReq?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const cotizaciones = await resp.json();
        url = `${this.props.urlws}/invMovs/ordenesReq?alm=${alm}&cve=${cve}&num=${num}`;
        resp = await fetch(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, ordenes,cotizaciones });
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(alm,cve,0);
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async (obj) =>{
        const mov = {
            Almacen : this.state.almacen,
            Clave : this.state.clave,
            Numero : obj.Numero,
            Nombre : '',
            Fecha : obj.Fecha,
            Concepto : obj.Concepto,
            Lote:obj.Lote,
            Cultivo:obj.Cultivo,
            Temporada:obj.Temporada,
            Solicito:obj.Solicito,
            S: JSON.stringify(obj.Detalle),
            L: JSON.stringify(obj.LotesCult)
        }
        const url = this.props.urlws +"/invMovs/wsguardarReq";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
        });
    }
    imprimir = async(mov) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Incluir todas las cotizaciones ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            let todo = result.value?"S":"N";
            let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero + '&todo=' + todo;
            let url = this.props.urlws + '/invmovs/imprimirReq' + params;
            window.open(url, '', '');    
          })        
    }
    enviar = async(mov,email) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Incluir todas las cotizaciones ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
            let todo = result.value?"S":"N";
            this.setState({cargando:true});
            const url = `${this.props.urlws}/invmovs/enviarReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&email=${email}&todo=${todo}`;
            const resp = await fetch(url);
            const res = await resp.text();
            this.setState({cargando:false});
            if(res === "ok")
                Swal.fire('Atencion','Se envio la requisicion','success');
            else
                Swal.fire('Atencion',res,'error');    
          })        
    }
    cambiarStatus = async(mov) =>{
        if(mov.Status === 1){
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Confirme',
                text: "¿ Iniciar el proceso de cotizacion para la requisicion " + mov.Folio + "?",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
              }).then(async(result) => {
                if (result.value) {
                    const status = mov.Status + 1;
                    this.setState({cargando:true});
                    const url = `${this.props.urlws}/invMovs/cambiarStatusReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&status=${status}`;
                    const resp = await fetch(url);
                    const txt = await resp.text();
                    this.setState({cargando:false });
                    if(txt === "ok")
                        this.cargarMovs();
                    else
                        Swal.fire('Atencion',txt,'error');
                }
              })            
        }
    }
    crearCotizacion = async(mov,prov,rens) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invMovs/crearCotizaReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&prov=${prov}&rens=${rens}`;
        const resp = await fetch(url);
        const txt = await resp.text();
        this.setState({cargando:false });
        if(txt === "ok"){
           Swal.fire('Atencion','Cotizacion creada con exito','success');
           this.cargarCotizas(mov.Almacen,mov.Clave,mov.Numero);
        }
        else
           Swal.fire('Atencion',txt,'error');
    }
    guardarPrecio = async(mov,id,pre,obs,sel) =>{
        const s = sel?"S":"N";
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invMovs/grabaCotizaReqPrecio?id=${id}&precio=${pre}&obs=${obs}&sel=${s}`;
        const resp = await fetch(url);
        const txt = await resp.text();
        this.setState({cargando:false });
        if(txt === "ok"){
           this.cargarCotizas(mov.Almacen,mov.Clave,mov.Numero);
        }
        else
           Swal.fire('Atencion',txt,'error');
    }
    crearOrdenes = async(mov,prov) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invMovs/crearOrdenesCompraReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&prov=${prov}`;
        const resp = await fetch(url);
        const txt = await resp.text();
        this.setState({cargando:false });
        if(!isNaN(txt)){
           Swal.fire('Atencion','Se crearon ' + txt + ' ordenes.','success');
           this.cargarCotizas(mov.Almacen,mov.Clave,mov.Numero);
        }
        else
           Swal.fire('Atencion',txt,'error');
    }

    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    {
                        this.state.entrada.Status < 2 ?
                        <Captura entrada={this.state.entrada} height={400} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} lotes={this.state.lotes} usuarios={this.state.usuarios}/>
                        :
                        <RequisicionesCotizar entrada={this.state.entrada} height={220} urlws={this.props.urlws} temp={this.state.Temporada} usuarios={this.state.usuarios} cancelar={this.cancelar} crearCotizacion={this.crearCotizacion} cotizaciones={this.state.cotizaciones} ordenes={this.state.ordenes} guardarPrecio={this.guardarPrecio} crearOrdenes={this.crearOrdenes} enviarEmail={this.enviarOrdenEmail}/>
                    }
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange}>
                                    <option key="0" value="??">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable entradas={this.state.entradas} height={500} width={990} onRowClick={this.cargarDetalle} imprimir={this.imprimir} enviar={this.enviar} cambiarStatus={this.cambiarStatus} />
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { entradas } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Nombre</th>
                        <th>Numero</th>
                        <th>Fecha</th>
                        <th>Status</th>
                        <th>Solicito</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {entradas.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} enviar={this.props.enviar} cambiarStatus={this.props.cambiarStatus} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    enviar = async(e) =>{
        const { value: email } = await Swal.fire({
            input: 'email',
            title: 'Enviar orden por email',
            inputValue:'',
            inputPlaceholder: 'ingrese la direccion de email'
          })
          
          if (email) {
            this.props.enviar(this.props.mov,email);
          }
    }

    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Clave}</td>
            <td>
                {
                    mov.Clave === "REQA" ?
                    <span>{mov.Nombre}</span>
                    :
                    <span>{mov.Concepto}</span>
                }
            </td>

            <td>{mov.Folio}</td>
            <td>{mov.Fecha}</td>
            <td>{mov.NomStatus}</td>
            <td>{mov.Sol}</td>
            <td>
                {
                    mov.Status === 1 ?
                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
                    :
                        <img src="/imagenes/cotizar.png" alt="cotizar" title="cotizar" onClick={this.onClick}/>
                }
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
            <td>
                <img src="/imagenes/email.png" alt="enviar por email" title="enviar por email" onClick={this.enviar}/>
            </td>
            <td>
                <img src="/imagenes/candado.png" alt="cambiar status" title="cambiar status" onClick={this.cambiar}/>
            </td>
        </tr>
        );
    }
}

class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agregando:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            codigo:'000009',
            nombre:'',
            articulo:{},
            cantidad:1,
            dosis:1.0,
            costo:0,
            importe:0,
            Lote:0,
            Cultivo:0,
            loteCult:0,
            renglon:0,
            renSig:1,
            indice:0,
            Detalle:[],
            cultivos:[],
            LotesCult:[],
            LoteAnt:0,
            esAgro:this.props.entrada.Clave === "REQA",
            esRef:this.props.entrada.Clave === "REQR",
            esServ:this.props.entrada.Clave === "REQS",
            ...this.props.entrada
        }
    }
    cargarCultivos = async (lote) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoTemp/${this.props.temp}?lote=${lote}`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, cultivos });
    }

    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            LoteAnt:0,
            dosis:1.0,
            esAgro:newProps.entrada.Clave === "REQA",
            esRef:newProps.entrada.Clave === "REQR",
            esServ:newProps.entrada.Clave === "REQS",
            ...newProps.entrada,
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true},()=>{
            if(campo === "Lote")
               this.cargarCultivos(valor);
        });
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    enter = (campo) =>{
        let foco = campo;
        if(campo === "Lote")
           foco = "cantidad";
        if(campo === "dosis"){
           foco = "cantidad";
           const dosis = Number(this.state.dosis);
           this.setState({dosis});
        }
        if(campo === "cantidad"){
            const cantidad = Number(this.state.cantidad);
            const dosis = Number(this.state.dosis);
            //const costo = Number(this.state.costo);
            //const importe = Number(cantidad * costo);
            //this.setState({cantidad, costo, importe,dosis});
            //foco = "costo";
            this.setState({cantidad, dosis},()=>{
                this.agregarRenglon();
            });
            foco = "codigo";
        }
        /*
        if(campo === "costo"){
            const cantidad = Number(this.state.cantidad);
            const costo = Number(this.state.costo);
            const importe = Number(cantidad * costo);
            const dosis = Number(this.state.dosis);
            this.setState({cantidad, costo, importe,dosis},() =>{
                this.agregarRenglon();
            });
            foco = "codigo";
         }
         */
         document.getElementById(foco).focus();
    }
    agregarRenglon = () =>{
        if(this.state.renglon === 0){
            const det = {
                Renglon:this.state.renSig,
                Codigo:this.state.codigo,
                Nombre:this.state.nombre,
                Cantidad:this.state.cantidad,
                Dosis:Number(this.state.dosis),
                Costo:Number(this.state.costo),
                Importe:this.state.importe,
                Lote:this.state.Lote,
                Cultivo:this.state.Cultivo
            };
            let Detalle = this.state.Detalle;
            Detalle.push(det);
            this.setState({cambio:true, Detalle,renSig:this.state.renSig + 1});
        }
        else{
            let Detalle = this.state.Detalle;
            const indice = this.state.indice;
            Detalle[indice].Codigo = this.state.codigo;
            Detalle[indice].Nombre = this.state.nombre;
            Detalle[indice].Cantidad = this.state.cantidad;
            Detalle[indice].Dosis = this.state.dosis;
            Detalle[indice].Costo = this.state.costo;
            Detalle[indice].Importe = this.state.importe;
            Detalle[indice].Lote = this.state.Lote;
            Detalle[indice].Cultivo = this.state.Cultivo;
            this.setState({mostrarModal:false, cambio:true});
        }
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === '' || articulo.Depto === 3){
            Swal.fire('Atencion','Este codigo no corresponde a un insumo o subproducto','error');
            this.setState({cargando:false});
            return;
        }
         this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            costo:articulo.Costo,
            importe:articulo.Costo * this.state.cantidad,
            articulo
        });
        if(this.state.esAgro)
            document.getElementById("dosis").focus();
        else
            document.getElementById("cantidad").focus();
    }
    agregarCultivo = async() =>{
        const { value: has } = await Swal.fire({
            title: 'Superficie',
            input: 'text',
            inputValue: '',
            inputPlaceholder: 'Ingrese las hectareas',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Especifica la superficie'
              }
            }
          });
          
          if (has) {
              let valor = Number(has);
              if(Number.isNaN(valor)){
                 Swal.fire('Atencion','Dato incorrecto','error');
                 return;
              }
              let LotesCult = this.state.LotesCult;
              let lote = this.state.Lote;
              let cult = this.state.Cultivo;
              let nomLote = '';
              let nomCult = '';
              console.log(lote);
              for (let i = 0; i < this.props.lotes.length; i++) {
                   console.log(this.props.lotes[i].I);
                  if(this.props.lotes[i].I == lote)
                     nomLote = this.props.lotes[i].N;
              }
              for (let i = 0; i < this.state.cultivos.length; i++) {
                  if(this.state.cultivos[i].I == cult)
                     nomCult = this.state.cultivos[i].N;
              }
              LotesCult.push({
                  Lote:lote,
                  Cultivo:cult,
                  NomLote:nomLote,
                  NomCult:nomCult,
                  Has:valor
              });
              this.setState({LotesCult});
         }            
    }
    quitarLoteCult = (i) =>{
        let {LotesCult} = this.state;
        LotesCult.splice(i, 1);
        this.setState({cambio:true, LotesCult});
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0, agregando:true});
    }
    quitarRenglon = (ren,i) =>{
        let Detalle = this.state.Detalle;
        Detalle.splice(i, 1);
        this.setState({cambio:true, Detalle});
    }
    editarRenglon = (ren,i) =>{
        const cargar = ren.Lote !== this.state.LoteAnt;
        this.setState({
            agregando:false,
            mostrarModal:true,
            renglon:ren.Renglon,
            codigo:ren.Codigo,
            nombre:ren.Nombre,
            cantidad:ren.Cantidad,
            dosis:ren.Dosis,
            costo:ren.Costo,
            importe:ren.Importe,
            Lote:ren.Lote,
            Cultivo:ren.Cultivo,
            indice:i,
            LoteAnt:ren.Lote
        });
        if(cargar)
           this.cargarCultivos(ren.Lote);
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        if(this.state.Solicito == 0){
            Swal.fire('Atencion','Especifique a quien solicita la requisicion','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    render(){
        const colS = this.state.esAgro ? "col-6" : "col";
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <span>Solicito</span><br />
                            <Input size="sm" type="select" name="Solicito" value={this.state.Solicito} onChange={this.onChange}>
                                <option key="0" value={0}>Especifique</option>
                                {this.props.usuarios.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Lote</span><br />
                            <Input size="sm" type="select" name="Lote" value={this.state.Lote} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.props.lotes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <span>Cultivo</span><br />
                            <Input size="sm" type="select" name="Cultivo" value={this.state.Cultivo} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.cultivos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <br/>
                        <Button size="sm" color="success" onClick={this.agregarCultivo}>+</Button>
                    </div>
                </div>
                {!this.state.esAgro ?
                                <div className="row">
                                <div className="col">
                                    <FormGroup>
                                        <span>Concepto</span><br />
                                        <Input size="sm" type="text" name="Concepto" value={this.state.Concepto} readOnly />
                                    </FormGroup>
                                </div>
                            </div>
                    : null
                }
                <div style={{ height: 4 }}></div>
                <div className="row">
                    <div className={colS}>
                        <div style={{ height: this.props.height, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 100 }}>Codigo</th>
                                        <th>Nombre</th>
                                        <th className="numero">Cantidad</th>
                                        {this.state.esAgro ?
                                            <th className="numero">Dosis</th>
                                            :
                                            <th>Destino</th>
                                        }
                                        {this.state.esServ ?
                                            <th>Servicio</th>
                                            :
                                            null
                                        }
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.Detalle.map((ren, i) => (
                                        <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon} onRowQuitar={this.quitarRenglon} esAgro={this.state.esAgro} esRef={this.state.esRef} esServ={this.state.esServ} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {this.state.esAgro ?
                    <div className="col-6">
                        <div style={{ height: this.props.height, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Lote</th>
                                        <th>Cultivo</th>
                                        <th className="numero">Hectareas</th>
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.LotesCult.map((ren, i) => (
                                        <tr key={i} indice={i} renglon={ren}>
                                            <td>{ren.NomLote}</td>
                                            <td>{ren.NomCult}</td>
                                            <td className="numero">{ren.Has.formato()}</td>
                                            <td><img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={()=>{ this.quitarLoteCult({i});}}/></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    :null
                    }
                </div>
                <div className="col">
                    <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.agregarDetalle}>+</Button>
                </div>

                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1} onOpened={()=>{document.getElementById("codigo").focus();}}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Codigo &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscar">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/articulo/articulos?depto=1&filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nombre} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.esAgro ?
                            <div className="col-2">
                                <FormGroup>
                                    <span>Dosis/Ha</span><br/>
                                    <Input size="sm" type="text" name="dosis" value={this.state.dosis} onChange={this.onChange} onKeyPress={(e) => {if(e.which == 13) this.enter("dosis");}} id="dosis"/>
                                </FormGroup>
                            </div> 
                            : null}
                            <div className="col-2">
                                <FormGroup>
                                    <span>Cantidad</span><br/>
                                    <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} onKeyPress={(e) => {if(e.which == 13) this.enter("cantidad");}} id="cantidad"/>
                                </FormGroup>
                            </div> 
                            <div className="col">
                                <FormGroup>
                                <span>&nbsp;</span><br/>
                                <Button size="sm" color='success' style={{visibility:this.state.agregando?'visible':'hidden'}} onClick={this.agregarRenglon}>+</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

class DetalleRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onQuitar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este renlon (" + this.props.renglon.Nombre + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.onRowQuitar(this.props.renglon, this.props.indice);
            }
        });
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Codigo}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero">{renglon.Cantidad.formato()}</td>
            {this.props.esAgro ?
                <td className="numero">{renglon.Dosis.formato()}</td>
            :
                <td>{renglon.Dest}</td>
            }
            {this.props.esServ ?
                <td>{renglon.Serv}</td>
                :
                null
            }
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onQuitar}/>
            </td>
        </tr>
        );
    }
}
import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';

export default class ConsumoDiesel extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            fechaIni:fecIni,
            fechaFin:fecFin,
            movimientos:[],
            tipos:[],
            temporadas:[],
            lotes:[],
            movimiento:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, almacenes, temporadas, lotes });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        const temp = this.state.Temporada;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/consumoDiesel?fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const movimientos = await resp.json();
        this.setState({cargando:false, movimientos });
    }
    cargarDetalle = (mov) =>{
        alert(mov.IdMaq);   
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/compras/imprimir' + params;
        window.open(url, '', '');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }

    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movimientos={this.state.movimientos} height={500} width="98%" onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>                
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movimientos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:80}}>Id</th>
                        <th>Maquina</th>
                        <th className="numero">Litros</th>
                    </tr>
                </thead>
                <tbody>
                    {movimientos.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td className="numero">{mov.IdMaq}</td>
            <td>{mov.Maq}</td>
            <td className="numero">{mov.Lts}</td>
        </tr>
        );
    }
}
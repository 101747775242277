import React from 'react';
import { Nav,Navbar,InputGroup, InputGroupAddon, Collapse, Table, Form, FormGroup, Badge, Input, Button, Container, Label } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class ActividadesCultivo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre:'?',
            cultivo:0,
            mesIni:0,
            mesFin:0,
            cargando:true,
            formVisible: false,
            actividad:{},
            cultivos:[],
            actividades: [],
            cultsAct: []
        };
        this.cargarActividades();
    }
    cargarActividades = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/actividades?id=${this.state.nombre}`;
        let resp = await fetch(url);
        const actividades = await resp.json();
        url = `${this.props.urlws}/agricola/cultivos`;
        resp = await fetch(url);
        const cultivos = await resp.json();
        const seleccion = cultivos.map(() => false);
        this.setState({cargando:false, actividades, cultivos,seleccion });
    }
    cargarCultivos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/cultivosActividad/${this.state.actividad.I}`;
        let resp = await fetch(url);
        const cultsAct = await resp.json();
        this.setState({cargando:false, cultsAct});
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.cargarActividades();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarActividades();
    }    
    cargarActividad = (i) =>{
        const actividad = this.state.actividades[i];
        this.setState({actividad}, () => this.cargarCultivos());
    }
    quitarCultivo = (i) =>{
        let {cultsAct} = this.state;
        cultsAct.splice(i, 1);
        this.setState({cultsAct});
    }
    agregarCultivo = async() =>{
        let cult = null;
        cult = this.state.cultivos[this.state.cultivo];
        if(cult === null)
           return;

        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/agregarActCult?act=${this.state.actividad.I}&cult=${cult.I}&mesIni=${this.state.mesIni}&mesFin=${this.state.mesFin}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
           Swal.fire('Atencion',res,'error');
           return;
        }
        this.cargarCultivos();
    }
    render(){
        const meses = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];
        return(
            <Container>
                <div className="row">
                    <div className="col">
                        <h5>Actividades</h5>
                        <Navbar bg="f5e831" expand="lg">
                            <Collapse isOpen={true} id="basic-navbar-nav">
                                <Nav className="mr-auto" size="sm">
                                    <Form inline onSubmit={this.onSubmit} >
                                        <label size="sm">Nombre</label>&nbsp;
                                        <InputGroup>
                                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{ width: 400 }} />
                                            <InputGroupAddon addonType="append">
                                                <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Form>
                                </Nav>
                            </Collapse>
                        </Navbar>
                        <div style={{ height:440, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 100 }}>Id</th>
                                    <th>Nombre</th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.actividades.map((o, i) => (
                                    <tr key={o.I}>
                                        <td>{o.I}</td>
                                        <td>{o.N}</td>
                                        <td>
                                            <img src="/imagenes/edit.png" onClick={() => this.cargarActividad(i)} alt="editar" title="editar" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                    <div className="col">
                        <h5>Cultivos de la actividad <Badge color="warning">{this.state.actividad.N}</Badge></h5>
                        <label size="sm">&nbsp;</label>&nbsp;
                        <div className="row">
                            <div className="col">
                                <Input size="sm" type="select" name="cultivo" id="cultivo" value={this.state.cultivo} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.state.cultivos.map((i,k) =>(
                                        <option key={i.I} value={k}>{i.N}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col">
                                <Input size="sm" type="select" name="mesIni" id="mesIni" value={this.state.mesIni} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {meses.map((i,k) =>(
                                        <option key={k} value={k+1}>{i}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col">
                                <Input size="sm" type="select" name="mesFin" id="mesFin" value={this.state.mesFin} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {meses.map((i,k) =>(
                                        <option key={k} value={k+1}>{i}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col">
                                <Button size="sm" color="success" onClick={this.agregarCultivo}>+</Button>
                            </div>
                            </div>
                            <div style={{height:10}}></div>
                        <div className="row">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Del mes</th>
                                    <th>Al mes</th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.cultsAct.map((o, i) => (
                                    <tr key={o.I}>
                                        <td>{o.Nom}</td>
                                        <td>{meses[o.Ini - 1]}</td>
                                        <td>{meses[o.Fin - 1]}</td>
                                        <td>
                                            <img src="/imagenes/trash.png" onClick={() => this.quitarCultivo(i)} alt="editar" title="editar" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

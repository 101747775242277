import React from 'react';
import { Button, Table } from 'reactstrap';

export default class Buscador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            datos: []
        };
    }
    componentDidMount(){
        const estado = localStorage.getItem("buscador");
        if(estado === null)
            return;
        this.setState(JSON.parse(estado));
    }
    componentWillUnmount(){
        const estado = JSON.stringify(this.state);
        localStorage.setItem("buscador", estado);
    }
    onChange = e => {
        this.setState({ nombre: e.target.value });
    }
    onKeyPress = e => {
        if (e.which === 13)
            this.cargar();
    }
    onClick = e => {
        this.cargar();
    }
    onRowClick = (item) => {
        this.props.onSelect(item);
        document.body.click();
    }
    cargar = async () => {
        const url = this.props.url + this.state.nombre;
        const res = await fetch(url);
        //alert(url);
        const datos = await res.json();
        this.setState({ datos });
    }
    render() {
        return (
            <div style={{padding:5}} className="body">
                <div className="row">
                    <div className="col-7">
                        <input type="text" className="form-control" placeholder="concepto" name="nombre" autoFocus value={this.state.nombre} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                    </div>
                    <div className="col"><Button onClick={this.onClick}>Buscar</Button></div>
                </div>
                <div style={{height:3}}></div>
                <div style={{ height: 300, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.datos.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => this.onRowClick(item)}>
                                        <td>{item.N}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}
import React from 'react';
import { Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2'

export default class ReportesInv extends React.Component{
    constructor(props){
        let fecha = new Date().yyyymmdd('-');
        super(props);
        this.state = {
            cargando:false,
            fechaIni:fecha,
            fechaFin:fecha,
            reporte:-1,
            almacen:0,
            deptoIni:0,
            deptoFin:0,
            lineaIni:0,
            lineaFin:0,
            sublinea:0,
            almacenes:[],
            deptos:[],
            lineas:[],
            sublineas:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/articulo/deptos`;
        resp = await fetch(url);
        const deptos = await resp.json();
        this.setState({ almacenes, deptos });
    }
    cargarLineas = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/lineas/${this.state.deptoIni}`;
        let resp = await fetch(url);
        const lineas = await resp.json();
        this.setState({ lineas });
    }
    cargarSublineas = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/sublineas?depto=${this.state.deptoIni}&linea=${this.state.lineaIni}`;
        let resp = await fetch(url);
        const sublineas = await resp.json();
        this.setState({ sublineas });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "deptoIni" || campo === "deptoFin"){
                if(valor > 0 && (this.state.deptoIni === this.state.deptoFin))
                   this.cargarLineas();
                else
                    this.setState({lineas:[]});
            }
            if(campo === "lineaIni" || campo === "lineaFin"){
                if(valor > 0 && (this.state.lineaIni === this.state.lineaFin))
                   this.cargarSublineas();
                else
                    this.setState({sublineas:[]});
            }
        });
    }
    imprimir = () =>{
        const rep = this.state.reporte;
        const alm = this.state.almacen;
        let {fechaIni} = this.state;
        let {fechaFin} = this.state;
        let {deptoIni} = this.state;
        let {deptoFin} = this.state;
        let {lineaIni} = this.state;
        let {lineaFin} = this.state;
        let {sublinea} = this.state;
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        if(deptoIni !== deptoFin){
            lineaIni = 0;
            lineaFin = 99999;
        }
        if(lineaIni === 0 || (lineaIni !== lineaFin))
           sublinea = 0;
        let url = this.props.urlws;
        if(this.props.rpt === 'rptLstEnt'){
            let url = `${this.props.urlws}/invReportes/listadoInvMovs?almacen=${alm}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=E`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptLstSal'){
            let url = `${this.props.urlws}/invReportes/listadoInvMovs?almacen=${alm}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=S`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptExisAct'){
            let url = `${this.props.urlws}/invReportes/existenciaActual?alm=${alm}&deptoIni=${deptoIni}&deptoFin=${deptoFin}&lineaIni=${lineaIni}&lineaFin=${lineaFin}&subLinea=${sublinea}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptExis'){
            let url = `${this.props.urlws}/invReportes/existencia?alm=${alm}&deptoIni=${deptoIni}&deptoFin=${deptoFin}&lineaIni=${lineaIni}&lineaFin=${lineaFin}&subLinea=${sublinea}&fecha=${fechaIni}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptSdosAlm'){
            let url = `${this.props.urlws}/invReportes/saldos?alm=${alm}&deptoIni=${deptoIni}&deptoFin=${deptoFin}&lineaIni=${lineaIni}&lineaFin=${lineaFin}&subLinea=${sublinea}&fechaIni=${fechaIni}&fechaFin=${fechaFin}`;
            window.open(url, '', '');
        }
        if(this.props.rpt === 'rptKardex'){
            let url = `${this.props.urlws}/invReportes/kardex?alm=${alm}&deptoIni=${deptoIni}&deptoFin=${deptoFin}&lineaIni=${lineaIni}&lineaFin=${lineaFin}&subLinea=${sublinea}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&codigo=`;
            window.open(url, '', '');
        }
    }
    render(){
        //if(this.state.deptoIni > 0 && (this.state.deptoIni === this.state.deptoFin))
        return (
            <container>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col">
                                <span size="sm">Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span size="sm">Del departamento</span><br/>
                                <Input size="sm" type="select" name="deptoIni" value={this.state.deptoIni} onChange={this.onChange}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.deptos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                            <div className="col">
                                <span size="sm">al departamento</span><br/>
                                <Input size="sm" type="select" name="deptoFin" value={this.state.deptoFin} onChange={this.onChange}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.deptos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span size="sm">De la linea</span><br/>
                                <Input size="sm" type="select" name="lineaIni" value={this.state.lineaIni} onChange={this.onChange}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.lineas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                            <div className="col">
                                <span size="sm">a la linea</span><br/>
                                <Input size="sm" type="select" name="lineaFin" value={this.state.lineFin} onChange={this.onChange}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.lineas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span size="sm">De la sublinea</span><br/>
                                <Input size="sm" type="select" name="sublinea" value={this.state.sublinea} onChange={this.onChange}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.sublineas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <span size="sm">Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </div>
                            <div className="col-3">
                                <span size="sm">Al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="col-6" style={{ padding: 10 }}>
                            <Button color="success" onClick={this.imprimir}>Imprimir</Button>
                        </div>
                    </div>
                </div>
            </container>
        )
    }
}

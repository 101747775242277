import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class OrdenesCompra extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            almacenes:[],
            ordenes:[],
            temporadas:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        this.setState({cargando:false, almacenes,temporadas});
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const temp = this.state.Temporada;
        const alm = this.state.almacen;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/ordenes?alm=${alm}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}`;
        let resp = await fetch(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, ordenes });
    }
    imprimirOrden = (num) =>{
        let params = '?alm=' + this.state.almacen + '&cve=OC&num=' + num;
        let url = this.props.urlws + '/invmovs/imprimirOrdenCompra' + params;
        window.open(url, '', '');
    }
    enviarOrdenEmail = async(alm,cve,num,email) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invmovs/enviarOrdenCompra?alm=${alm}&cve=${cve}&num=${num}&email=${email}`;
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok")
            Swal.fire('Atencion','Se envio la orden','success');
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }    
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br />
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br />
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br />
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <div>
                    {this.state.ordenes.map((ren, i) => (
                        <OrdenRow key={i} indice={i} renglon={ren} imprimirOrden={this.imprimirOrden} enviarOrden={this.enviarOrden}/>
                    ))}
                    </div>
                </div>
            </Container>
        )
    }
}
class OrdenRow extends React.Component{
    imprimir = () =>{
        this.props.imprimirOrden(this.props.renglon.Numero)
    }
    email = async() =>{
        const { value: email } = await Swal.fire({
            input: 'email',
            title: 'Enviar orden por email',
            inputValue:this.props.renglon.Email,
            inputPlaceholder: 'ingrese la direccion de email'
          })
          
          if (email) {
            this.props.enviarOrden(this.props.renglon.Numero,email)
          }
    }
    render(){
        let {renglon} = this.props;
        let tam = renglon.Detalle.length;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-1"><b>{renglon.Numero}</b></div>
                    <div className="col-9"><b>{renglon.Prov}</b></div>
                    <div className="col-1">
                        <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir} />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <img src="/imagenes/email.png" alt="enviar por email" title="enviar por email" onClick={this.email} />
                    </div>
                </div>
                <div className="row">
                    {tam > 0 ?
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Codigo</th>
                                <th>Nombre</th>
                                <th className="numero" style={{width:100}}>Cantidad</th>
                                <th className="numero" style={{width:100}}>Precio</th>
                                <th className="numero" style={{width:100}}>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renglon.Detalle.map((ren, i) => (
                                <tr>
                                    <td>{ren.Codigo}</td>
                                    <td>{ren.Nombre}</td>
                                    <td className="numero">{ren.Cantidad.formato()}</td>
                                    <td className="numero">{ren.Precio.formato()}</td>
                                    <td className="numero">{ren.Importe.formato()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    : null
                    }
                </div>
                <br/>
            </div>
        );
    }
}
import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class RequisicionesCotizar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            prov:0,
            nomProv:'',
            popoverOpen:false,
            mostrarModal:false,
            Detalle:[],
            cotizaciones:this.props.cotizaciones,
            ...this.props.entrada
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cotizaciones:newProps.cotizaciones,
            ...newProps.entrada,
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    cancelar = () =>{
        this.props.cancelar();
    }
    crearCotiza = () =>{
        let k = 0;
        let rens = "";
        let sepa = "";
        let prov = this.state.prov;
        if(prov === 0){
            Swal.fire('Atencion','Especifique el proveedor','error');
            return;
        }
        for(k = 0; k < this.state.Detalle.length; k++)
            if(this.state.Detalle[k].Sel){
                rens = rens + sepa + this.state.Detalle[k].Renglon;
                sepa = ",";
            }
        if(rens === ""){
            Swal.fire('Atencion','Especifique al menos un producto para cotizar','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
                title: 'Confirme',
                text: "¿ Desea crear cotizacion para el proveedor "  + this.state.nomProv + " ?",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
              }).then((result) => {
                if (result.value) {
                    this.props.crearCotizacion(this.props.entrada,prov,rens);
                }
        });
    }
    guardarPrecio = (id,pre,obs,sel) =>{
        this.props.guardarPrecio(this.props.entrada,id,pre,obs,sel);
    }
    crearOrdenes = () =>{
        this.props.crearOrdenes(this.props.entrada,0);
    }
    seleccionar = (i,v) =>{
        this.state.Detalle[i].Sel = v;
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    provSel = (item) =>{
        this.setState({popoverOpen:false, prov: item.I, nomProv:item.N});
    }
    imprimirOrden = (num) =>{
        let params = '?alm=' + this.props.entrada.Almacen + '&cve=OC&num=' + num;
        let url = this.props.urlws + '/invmovs/imprimirOrdenCompra' + params;
        window.open(url, '', '');
    }
    enviarOrden = (num,email) =>{
        this.props.enviarEmail(this.props.entrada.Almacen,'OC',num,email);
    }
    editarCantidad = async(ren,idx) =>{
        console.log(ren);
        const { value: cant } = await Swal.fire({
            input: 'text',
            title: ren.Nombre,
            inputValue:ren.Cantidad,
            showCancelButton:true,
            cancelButtonText:'Cancelar',
            cancelButtonColor:'#f00',
            inputPlaceholder: 'ingrese la cantidad'
          })
          
          if (cant) {
              const url = this.props.urlws + `/invmovs/cambiarCantReq?alm=${this.state.Almacen}&cve=${this.state.Clave}&num=${this.state.Numero}&ren=${ren.Renglon}&cant=${cant}`;
              const resp = await fetch(url);
              const res = await resp.text();
              if(res === "ok"){
                Swal.fire('Atencion','Datos guardados','success');
                this.state.Detalle[idx].Cantidad = Number(cant);
                this.setState({editando:false});
            }
            else
                Swal.fire('Atencion',res,'error');    
          }

    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-4">
                        <FormGroup>
                            <span>Solicito</span><br />
                            <Input size="sm" type="select" name="Solicito" value={this.state.Solicito} onChange={this.onChange}>
                                <option key="0" value={0}>Especifique</option>
                                {this.props.usuarios.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FormGroup>
                            <span>Concepto</span><br />
                            <Input size="sm" type="text" name="Concepto" value={this.state.Concepto} readOnly />
                        </FormGroup>
                    </div>
                </div>                    
                <div style={{ height: 4 }}></div>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Codigo</th>
                                <th>Nombre</th>
                                <th className="numero" style={{width:80}}>Cantidad</th>
                                <th style={{width:80}}>Unidad</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                                <th style={{width:30, textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} seleccionar={this.seleccionar} onRowClick={this.editarCantidad}/>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="col-2">
                        <InputGroup>
                            <label>Proveedor &nbsp;</label>
                            <Input size="sm" type="text" name="prov" value={this.state.prov} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="prov" autoFocus />
                            <InputGroupAddon addonType="append">
                                <Button size="sm" color="success" id="btnBuscar">
                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                </Button>
                                <Popover placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                    <PopoverBody>
                                        <Buscador onSelect={this.provSel} url={this.props.urlws + "/prov/lista?filtro="} />
                                    </PopoverBody>
                                </Popover>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="col-4">
                        <Input size="sm" type="text" value={this.state.nomProv} readOnly/>
                    </div>
                    <div className="col">
                        <Button size="sm" color="success" onClick={this.crearCotiza}>Cotizar</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" color="success" onClick={this.crearOrdenes}>Crear ordenes de compra</Button>
                    </div>
                </div>
                <br/>
                <h6>COTIZACIONES</h6>
                <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Proveedor</th>
                                <th className="numero">Precio</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                                <th>Observaciones</th>
                                <th style={{width:30, textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.cotizaciones.map((ren, i) => (
                                <CotizaRow key={i} indice={i} renglon={ren} guardar={this.guardarPrecio}/>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <br/>
                <h6>ORDENES DE COMPRA</h6>
                <div>
                    {this.props.ordenes.map((ren, i) => (
                        <OrdenRow key={i} indice={i} renglon={ren} imprimirOrden={this.imprimirOrden} enviarOrden={this.enviarOrden}/>
                    ))}
                </div>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalBody>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

class DetalleRow extends React.Component{
    constructor(props){
        super(props);
        this.state = {Sel:this.props.renglon.Sel};
    }
    componentWillReceiveProps(newProps){
        this.setState({
            Sel:newProps.renglon.Sel
        });
    }
    onClick = (e) =>{
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onChange = (e) =>{
        this.setState({Sel:e.target.checked});
        this.props.seleccionar(this.props.indice,e.target.checked);
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Codigo}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero">{renglon.Cantidad.formato()}</td>
            <td >{renglon.Unidad}</td>
            <td>
                <input type="checkbox" checked={this.state.Sel} onChange={this.onChange}/>
            </td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}
class CotizaRow extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            Sel:this.props.renglon.Sel,
            popoverOpen:false
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            Sel:newProps.renglon.Sel
        });
    }
    onClick = (e) =>{
        this.toggle();
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    guardar = (id,pre,obs,sel) => {
        this.setState({popoverOpen: false});
        this.props.guardar(id,pre,obs,sel)
    }    
    render(){
        let {renglon} = this.props;
        let estilo = renglon.Sel ? {fontWeight:"bold", color:'red'} : {};
        return (
        <tr style={estilo}>
            <td>{renglon.NomArt}</td>
            <td>{renglon.NomProv}</td>
            <td className="numero">{renglon.Precio}</td>
            <td>
                <input type="checkbox" checked={this.state.Sel} onChange={this.onChange}/>
            </td>
            <td>{renglon.Observaciones}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" id={"img" + renglon.Id} onClick={this.onClick}/>
                    <Popover placement="left" isOpen={this.state.popoverOpen} target={"img" + renglon.Id} toggle={this.toggle} trigger="legacy">
                        <PopoverBody>
                            < EditaPrecio dato={renglon} guardar={this.guardar}/>
                        </PopoverBody>
                    </Popover>
            </td>
        </tr>
        );
    }
}
class EditaPrecio extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            precio:this.props.dato.Precio,
            observaciones:this.props.dato.Observaciones,
            sel:this.props.dato.Sel
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            precio:newProps.dato.Precio,
            observaciones:newProps.dato.Observaciones,
            sel:newProps.dato.Sel
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    onChangeCh = (e) => {
        const campo = e.target.name;
        const valor = e.target.checked;
        this.setState({ [campo] : valor});
    }
    guardar = () =>{
        this.props.guardar(this.props.dato.Id,this.state.precio,this.state.observaciones,this.state.sel);
    }
    render(){
        return(
            <div className="container">
                <label>Producto</label><br/>
                <label>{this.props.dato.NomArt}</label><br/>
                <label>Proveedor</label><br/>
                <label>{this.props.dato.NomProv}</label><br/>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Precio&nbsp;</label>
                            <Input size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Seleccionar</label><br/>
                            <input name="sel" type="checkbox" checked={this.state.sel} onChange={this.onChangeCh}/>
                        </FormGroup>
                    </div>
                </div>
                <FormGroup  >
                    <label>Observaciones</label>
                    <textarea class="form-control" rows="5" name="observaciones" value={this.state.observaciones} onChange={this.onChange}></textarea>
                </FormGroup>
                <Button color="success" onClick={this.guardar}>Aceptar</Button>
            </div>
        )
    }
}
class OrdenRow extends React.Component{
    imprimir = () =>{
        this.props.imprimirOrden(this.props.renglon.Numero)
    }
    email = async() =>{
        const { value: email } = await Swal.fire({
            input: 'email',
            title: 'Enviar orden por email',
            inputValue:this.props.renglon.Email,
            inputPlaceholder: 'ingrese la direccion de email'
          })
          
          if (email) {
            this.props.enviarOrden(this.props.renglon.Numero,email)
          }
    }
    render(){
        let {renglon} = this.props;
        let tam = renglon.Detalle.length;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-1"><b>{renglon.Numero}</b></div>
                    <div className="col-9"><b>{renglon.Prov}</b></div>
                    <div className="col-1">
                        <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir} />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <img src="/imagenes/email.png" alt="enviar por email" title="enviar por email" onClick={this.email} />
                    </div>
                </div>
                <div className="row">
                    {tam > 0 ?
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Codigo</th>
                                <th>Nombre</th>
                                <th className="numero" style={{width:100}}>Cantidad</th>
                                <th className="numero" style={{width:100}}>Precio</th>
                                <th className="numero" style={{width:100}}>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renglon.Detalle.map((ren, i) => (
                                <tr>
                                    <td>{ren.Codigo}</td>
                                    <td>{ren.Nombre}</td>
                                    <td className="numero">{ren.Cantidad.formato()}</td>
                                    <td className="numero">{ren.Precio.formato()}</td>
                                    <td className="numero">{ren.Importe.formato()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    : null
                    }
                </div>
                <br/>
            </div>
        );
    }
}
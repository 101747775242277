import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Buscador from '../Buscador';
import { async } from 'q';

export default class CapturaEmpleado extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cargando:false,
            editando:false,
            mostrarModal:false,
            popoverProvOpen:false,
            popoverConOpen:false,
            Temporada:this.props.temp,
            okCon:false,
            tipo:'P',
            importe:0,
            empresa:1,
            emp:0,
            nomEmp:'',
            concepto:0,
            nomCon:'',
            empleado:{},
            empresas:[],
            nomina:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empresa/empresas`;
        let resp = await fetch(url);
        const empresas = await resp.json();
        this.setState({cargando:false, empresas});
    }
    cargarNomina = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/cargarNomEmp?empresa=${this.state.empresa}&empleado=${this.state.emp}`;
        let resp = await fetch(url);
        const nomina = await resp.json();
        this.setState({cargando:false, nomina});
    }
    cargarEmpleado = async (idEmp) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/cargarEmpleado/${this.state.emp}?empresa=${this.state.empresa}`;
        let resp = await fetch(url);
        const empleado = await resp.json();
        const nomEmp = empleado.Nombre + ' ' + empleado.ApePat + ' ' + empleado.ApeMat;
        this.setState({cargando:false, empleado, nomEmp},()=>{
            this.cargarNomina();
        });
    }
    cargarConcepto = async () => {
        this.setState({cargando:true});
        let con = parseInt(this.state.concepto);
        let url = `${this.props.urlws}/nomina/cargarConcepto/${con}?empresa=${this.state.empresa}`;
        let resp = await fetch(url);
        const obj = await resp.json();
        const nomCon = obj.Nombre;
        const tipo = obj.Tipo;
        const okCon = obj.Id === con;
        this.setState({cargando:false, nomCon, tipo, okCon});
        if(okCon)
           document.getElementById("importe").focus();
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            //if(campo === "Temporada")
            //   this.cargarLotes();
        });
    }
    toggleEmp = () => {
        this.setState({popoverProvOpen: !this.state.popoverProvOpen});
    }    
    toggleCon = () => {
        this.setState({popoverConOpen: !this.state.popoverConOpen});
    }    
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    empSel = (item) =>{
        this.setState({popoverProvOpen:false, emp: item.I, nomEmp:item.N},() =>{
            this.cargarEmpleado(item.I);
        });
    }
    conSel = (item) =>{
        this.setState({popoverConOpen:false, concepto: item.I, nomCon:item.N},() =>{
            this.cargarConcepto(item.I);
        });
    }
    onKeyPressEmp = (e) =>{
        if(e.which == 13)
           this.cargarEmpleado(this.state.emp);
    }
    onKeyPressConcepto = (e) =>{
        if(e.which == 13)
           this.cargarConcepto();
    }
    onKeyPressImporte = (e) =>{
        if(e.which == 13)
           this.agregar();
    }
    agregarConcepto = () =>{
        if(this.state.emp === 0){
            Swal.fire('Atencion','Especifique el empleado','error');
            return;
        }
        this.setState({mostrarModal:true});
    }
    agregar = () =>{
        if(!this.state.okCon){
            Swal.fire('Atencion','Especifique el concepto','error');
            return;
        }
        let nuevo = true;
        let concepto = parseInt(this.state.concepto);
        for (let i = 0; i < this.state.nomina.length; i++) {
            if(this.state.nomina[i].Id === concepto){
                this.state.nomina[i].Importe = Number(this.state.importe);
                nuevo = false;
            }
        }
        if(nuevo){
            const con = {
                Id : concepto,
                N: this.state.nomCon,
                T:this.state.tipo,
                Importe:Number(this.state.importe)
            };
            this.state.nomina.push(con);
        }
        concepto = 0;
        this.setState({concepto, importe:0, nomCon:''});
        document.getElementById("concepto").focus();
    }
    cancelar = async() =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Salir sin guardar los cambios del empleado " + this.state.nomEmp +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
              if(result.value)
                 this.setState({nomina:[],emp:0, nomEmp:''});
          })        
    }    

    guardar = async() =>{
        if(this.state.emp === 0){
            Swal.fire('Atencion','Especifique el empleado','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Guardar la nomina del empleado " + this.state.nomEmp +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
              if(result.value)
                 this.guardarB();
          })        
    }    

    guardarB = async () =>{
        const mov = {
            empresa : this.state.empresa,
            empleado : this.state.emp,
            s : JSON.stringify(this.state.nomina)
        }
        const url = this.props.urlws +"/nomina/guardarNomEmp";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false,nomina:[],emp:0, nomEmp:''});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(
            <Container>
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-3">
                            <FormGroup>
                                <span>Empresa</span><br />
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <span>Empleado </span><br />
                            <InputGroup>
                                <Input size="sm" type="text" name="emp" value={this.state.emp} onChange={this.onChange} onKeyPress={this.onKeyPressEmp} id="emp" autoFocus />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" id="btnBuscarEmp">
                                        <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                    </Button>
                                    <Popover trigger="legacy" placement="right" isOpen={this.state.popoverProvOpen} target="btnBuscarEmp" toggle={this.toggleEmp} >
                                        <PopoverBody>
                                            <Buscador onSelect={this.empSel} url={this.props.urlws + "/nomina/empleadosLista?empresa=" + this.state.empresa + "&filtro="} />
                                        </PopoverBody>
                                    </Popover>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                        <div className="col">
                            <FormGroup>
                                <span>Nombre</span><br />
                                <Input size="sm" type="text" name="nomEmp" value={this.state.nomEmp} readOnly />
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-1">
                                <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                            </div>
                            <div className="col-1">
                                <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                            </div>
                            <div className="col-9">
                            </div>
                            <div className="col-1">
                                <Button size="sm" color="success" onClick={this.agregarConcepto}>+</Button>
                            </div>
                        </div>
                        <div style={{height:4}}></div>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 30 }}>Id</th>
                                    <th>Nombre</th>
                                    <th className="numero" style={{ width: 100 }}>Unidades</th>
                                    <th className="numero" style={{ width: 100 }}>Percepciones</th>
                                    <th className="numero" style={{ width: 100 }}>Deducciones</th>
                                    <th style={{ width: 30 }}></th>
                                    <th style={{ width: 30 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.nomina.map((o, i) => (
                                    <tr>
                                        <td className="numero" style={{ width: 30 }}>{o.Id}</td>
                                        <td>{o.N}</td>
                                        {o.T === "U" ?
                                        <td className="numero">{o.Importe.formato()}</td>:
                                        <td></td>
                                        }                                        
                                        {o.T === "P" ?
                                        <td className="numero">{o.Importe.formato()}</td>:
                                        <td></td>
                                        }                                        
                                        {o.T === "D" ?
                                        <td className="numero">{o.Importe.formato()}</td>:
                                        <td></td>
                                        }
                                        <td><img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/></td>
                                        <td><img src="/imagenes/quitar.png" onClick={this.onClick} alt="eliminar" title="eliminar"/></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal size="sm" id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} tabIndex={1} onOpened={()=>{document.getElementById("concepto").focus();}}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <FormGroup >
                                    <label>Concepto &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="concepto" value={this.state.concepto} onChange={this.onChange} onKeyPress={this.onKeyPressConcepto} id="concepto" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscar">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverConOpen} target="btnBuscar" toggle={this.toggleCon} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.conSel} url={this.props.urlws + "/nomina/conceptos?id="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup >
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nomCon} readOnly />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <FormGroup >
                                    <label>Importe &nbsp;</label>
                                    <Input size="sm" type="text" id="importe" name="importe" value={this.state.importe} onChange={this.onChange} onKeyPress={this.onKeyPressImporte}/>
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup >
                                    <label> &nbsp;</label>
                                    <Button size="sm" color="success" onClick={this.agregar}>Agregar</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class Captura extends React.Component{
    render(){
        return (
            <div></div>
        )
    }
}
import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class Salidas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            Temporada:this.props.temp,
            tipo:'S',
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave:'??',
            tipos:[],
            almacenes:[],
            entradas:[],
            usuarios:[],
            temporadas:[],
            lotes:[],
            cultivos:[],
            entrada:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/invMovs/tiposInv/S`;
        resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/usuario/usuarios`;
        resp = await fetch(url);
        const usuarios = await resp.json();
        url = `${this.props.urlws}/agricola/temporadas`;
        resp = await fetch(url);
        const temporadas = await resp.json();
        url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, almacenes, usuarios, temporadas, lotes, tipos });
    }
    cargarCultivos = async (lote) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoTemp/${this.props.temp}?lote=${lote}`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, cultivos });
    }
    cargarLotes = async() =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/agricola/lotesTemp/${this.state.Temporada}`;
        const resp = await fetch(url);
        const lotes = await resp.json();
        this.setState({cargando:false, lotes });
    }
    cargarMovs = async () =>{
        if(this.state.Temporada === ''){
            Swal.fire('Atencion','Especifique la temporada','error');
            return;
        }
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const temp = this.state.Temporada;
        const alm = this.state.almacen;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/invmovs?alm=${alm}&cve=${cve}&fecIni=${fecIni}&fecFin=${fecFin}&temp=${temp}&tipo=S&depto=${this.props.depto}`;
        let resp = await fetch(url);
        const entradas = await resp.json();
        this.setState({cargando:false, entradas });
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Almacen,mov.Clave,mov.Numero);
    }
    cargarMov = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/cargar?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const entrada = await resp.json();
        if(entrada.Numero === 0)
           entrada.Temporada = this.state.Temporada;
        this.setState({cargando:false, editando:true, entrada});
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(alm,cve,0);
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async (obj) =>{
        const mov = {
            Almacen : this.state.almacen,
            Clave : this.state.clave,
            Numero : obj.Numero,
            Tipo : this.state.tipo,
            Depto: this.props.depto,
            Nombre : '',
            Fecha : obj.Fecha,
            Concepto : '',
            Temporada:obj.Temporada,
            Status:1,
            S: JSON.stringify(obj.Detalle)
        }
        const url = this.props.urlws +"/invMovs/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor},() =>{
            if(campo === "Temporada")
               this.cargarLotes();
        });
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/invmovs/imprimirReq' + params;
        window.open(url, '', '');
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura entrada={this.state.entrada} height={450} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} temp={this.state.Temporada} lotes={this.state.lotes} usuarios={this.state.usuarios}/>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup>
                                <span>Temporada</span><br/>
                                <Input size="sm" type="select" name="Temporada" value={this.state.Temporada} onChange={this.onChange} style={{  fontSize:'12px'}}>
                                    <option key="0" value="">Especifique</option>
                                    {this.state.temporadas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange}>
                                    <option key="0" value="??">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable entradas={this.state.entradas} height={500} width={990} onRowClick={this.cargarDetalle} imprimir={this.imprimir} cambiarStatus={this.cambiarStatus} />
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { entradas } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Numero</th>
                        <th>Fecha</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {entradas.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Clave}</td>
            <td>{mov.Numero}</td>
            <td>{mov.Fecha}</td>
            <td>
                {
                    mov.Status === 1 ?
                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
                    :
                        <img src="/imagenes/cotizar.png" alt="cotizar" title="cotizar" onClick={this.onClick}/>
                }
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
            <td>
                <img src="/imagenes/candado.png" alt="cambiar status" title="cambiar status" onClick={this.cambiar}/>
            </td>
        </tr>
        );
    }
}

class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            popoverOpenDest:false,
            agregando:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            codigo:'000009',
            nombre:'',
            nombreDest:'',
            articulo:{},
            destino:{},
            cantidad:1,
            costo:0,
            importe:0,
            Lote:0,
            Cultivo:0,
            loteCult:0,
            cc:0,
            renglon:0,
            renSig:1,
            indice:0,
            cultivos:[],
            Detalle:[],
            ...this.props.entrada
        }
    }
    cargarCultivos = async (lote) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/lotesCultivoTemp/${this.props.temp}?lote=${lote}`;
        let resp = await fetch(url);
        const cultivos = await resp.json();
        this.setState({cargando:false, cultivos });
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            LoteAnt:0,
            dosis:1.0,
            ...newProps.entrada,
        });
    }
    cargarDestino = async (idDest) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/agricola/cargarDestino/${idDest}`;
        let resp = await fetch(url);
        const destino = await resp.json();
        this.setState({cargando:false, destino, cc:idDest, nombreDest:destino.Nombre });
        document.getElementById("cantidad").focus();
    }
    codigoDest = (item) =>{
        this.setState({popoverOpenDest:false});
        this.cargarDestino(item.I);
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true},()=>{
            if(campo === "Lote")
               this.cargarCultivos(valor);
        });
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    onKeyPressDestino = (e) =>{
        if(e.which == 13)
           this.cargarDestino(this.state.cc);
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    toggleDest = () => {
        this.setState({popoverOpenDest: !this.state.popoverOpenDest});
    }    
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    enter = (campo) =>{
        let foco = campo;
        if(campo === "Lote")
           foco = "cantidad";
        if(campo === "cantidad"){
            const cantidad = Number(this.state.cantidad);
            //const costo = Number(this.state.costo);
            //const importe = Number(cantidad * costo);
            //this.setState({cantidad, costo, importe,dosis});
            //foco = "costo";
            this.setState({cantidad},()=>{
                this.agregarRenglon();
            });
            foco = "codigo";
        }
        /*
        if(campo === "costo"){
            const cantidad = Number(this.state.cantidad);
            const costo = Number(this.state.costo);
            const importe = Number(cantidad * costo);
            const dosis = Number(this.state.dosis);
            this.setState({cantidad, costo, importe,dosis},() =>{
                this.agregarRenglon();
            });
            foco = "codigo";
         }
         */
         document.getElementById(foco).focus();
    }
    agregarRenglon = () =>{
        if(this.state.renglon === 0){
            const det = {
                Renglon:this.state.renSig,
                Codigo:this.state.codigo,
                Nombre:this.state.nombre,
                NombreDest:this.state.nombreDest,
                Cantidad:this.state.cantidad,
                Costo:Number(this.state.costo),
                Importe:this.state.importe,
                Lote:this.state.Lote,
                Cultivo:this.state.Cultivo,
                CC:this.state.cc,
                Status:1
            };
            let Detalle = this.state.Detalle;
            Detalle.push(det);
            this.setState({cambio:true, Detalle,renSig:this.state.renSig + 1});
        }
        else{
            let Detalle = this.state.Detalle;
            const indice = this.state.indice;
            Detalle[indice].Codigo = this.state.codigo;
            Detalle[indice].Nombre = this.state.nombre;
            Detalle[indice].NombreDest = this.state.nombreDest;
            Detalle[indice].Cantidad = this.state.cantidad;
            Detalle[indice].Costo = this.state.costo;
            Detalle[indice].Importe = this.state.importe;
            Detalle[indice].CC = this.state.cc;
            Detalle[indice].Lote = this.state.Lote;
            Detalle[indice].Cultivo = this.state.Cultivo;
            this.setState({mostrarModal:false, cambio:true});
        }
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === '' || articulo.Depto === 3){
            Swal.fire('Atencion','Este codigo no corresponde a un insumo o subproducto','error');
            this.setState({cargando:false});
            return;
        }
         this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            costo:articulo.Costo,
            importe:articulo.Costo * this.state.cantidad,
            articulo
        });
        document.getElementById("cc").focus();
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0, agregando:true});
    }
    quitarRenglon = (ren,i) =>{
        let Detalle = this.state.Detalle;
        Detalle.splice(i, 1);
        this.setState({cambio:true, Detalle});
    }
    editarRenglon = (ren,i) =>{
        const cargar = ren.Lote !== this.state.LoteAnt;
        this.setState({
            agregando:false,
            mostrarModal:true,
            renglon:ren.Renglon,
            codigo:ren.Codigo,
            nombre:ren.Nombre,
            nombreDest:ren.NombreDest,
            cantidad:ren.Cantidad,
            cc:ren.CC,
            costo:ren.Costo,
            importe:ren.Importe,
            Lote:ren.Lote,
            Cultivo:ren.Cultivo,
            indice:i,
            LoteAnt:ren.Lote,
        });
        if(cargar)
           this.cargarCultivos(ren.Lote);
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        //if(this.state.Solicito == 0){
        //    Swal.fire('Atencion','Especifique a quien solicita la requisicion','error');
        //    return;
        //}
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ height: this.props.height, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 100 }}>Codigo</th>
                                        <th>Nombre</th>
                                        <th className="numero">Cantidad</th>
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.Detalle.map((ren, i) => (
                                        <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon} onRowQuitar={this.quitarRenglon} esAgro={this.state.esAgro} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.agregarDetalle}>+</Button>
                </div>

                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1} onOpened={()=>{document.getElementById("codigo").focus();}}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Codigo &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscar">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/articulo/articulos?depto=1&filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nombre} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Destino &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="cc" value={this.state.cc} onChange={this.onChange} onKeyPress={this.onKeyPressDestino} id="cc" />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscarDest">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpenDest} target="btnBuscarDest" toggle={this.toggleDest} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoDest} url={this.props.urlws + "/agricola/destinos?filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nombreDest} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <FormGroup>
                                    <span>Cantidad</span><br/>
                                    <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} onKeyPress={(e) => {if(e.which == 13) this.enter("cantidad");}} id="cantidad"/>
                                </FormGroup>
                            </div> 
                            <div className="col">
                                <FormGroup>
                                <span>&nbsp;</span><br/>
                                <Button size="sm" color='success' style={{visibility:this.state.agregando?'visible':'hidden'}} onClick={this.agregarRenglon}>+</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

class DetalleRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onQuitar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este renlon (" + this.props.renglon.Nombre + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.onRowQuitar(this.props.renglon, this.props.indice);
            }
        });
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Codigo}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero">{renglon.Cantidad.formato()}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onQuitar}/>
            </td>
        </tr>
        );
    }
}